<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
      <v-list-item-subtitle
        v-if="formattedItem.inf_comercial"
      >
        {{ formattedItem.inf_comercial }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <b10-list-item-right-avatar>
      {{ formattedItem.precio_total|currency }}
    </b10-list-item-right-avatar>
    <v-list-item-icon>
      <v-btn
        v-if="hasPermBorrar"
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-icon>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { stringToHslColor } from '@/utils/ui'
import { calculateMd5 } from '@/utils/crypto'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    hasPermBorrar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$online.presupuestoCuota.title(this.item)
      item.subtitle = this.$online.presupuestoCuota.subtitle(this.item)
      item.avatar = this.$options.filters.initials(item.title)
      item.avatarColor = stringToHslColor(calculateMd5(item.title))
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    },
  },
}
</script>
